<template>
  <div v-if="richtext" v-editable="blok" class="rte" v-html="richtext" />
</template>

<script lang="ts" setup>
import { ISbSchema } from '@storyblok/js';
import { RichTextEditorBlokProps } from '@/types';

// Plugins
const { $rteSchema } = useNuxtApp();

// Storyblok props
const props = defineProps<{
  blok: RichTextEditorBlokProps;
}>();

// Paragraph Text
const richtext = computed(() => {
  return props.blok.text &&
    props.blok.text.content &&
    props.blok.text.content[0].content
    ? renderRichText(props.blok.text, {
        schema: $rteSchema as ISbSchema | undefined,
      })
    : '';
});
</script>

<style lang="scss">
.rte {
  section > & {
    @include container;

    h1:first-child,
    .text-1:first-child {
      margin-block-end: 0.75em;
    }
  }

  section > &,
  .article__components & {
    * + *:not(li) {
      margin-block-start: 1.25em;
    }

    .eyebrow + * {
      margin-block-start: var(--spacing-xs);
    }
  }

  p ~ p {
    margin-block-start: var(--spacing-xs);
  }
}
</style>
